import React from 'react';
import {ErrorMessage} from "formik";

const InputComponent = ({field, form: {touched, errors}, ...props}) => {


    const error = touched && errors[field.name];
    let className = props.className ?? ''

    if (error)
        className = 'is-invalid'


    return <div className={className}>

        <textarea
            {...field}
            className={props.inputClassName}
        />

        <ErrorMessage name={field.name} component="div"/>
    </div>
}

export default InputComponent;
