import React, {useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import constants from "../../constants";
import {authenticationService} from "../../services/authentication.service";
import {UserRoles} from "backend/dist/user/user.entity";
import {Collapse} from "bootstrap/dist/js/bootstrap.esm";

const MenuLoggedIn = props => {

    let collapseList = useRef([]);

    useEffect(() => {
        const collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))

        collapseList.current = collapseElementList.map(function (collapseEl) {
            return new Collapse(collapseEl, {
                toggle: false
            })
        })
    })

    return <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">

            {authenticationService.currentUserValue.logoEmpresa?.length > 0 &&
            <a className="navbar-brand" href="#">
                <img src={`data:image/png;base64,${authenticationService.currentUserValue.logoEmpresa}`} alt="" width="30" height="24" />
            </a>
            }

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0" onClick={() => window.innerWidth < 992 && collapseList.current[0].toggle()}>
                    <li className="nav-item">
                        <Link
                            className="nav-link"
                            to={authenticationService.currentUserValue.role === UserRoles.ADMIN ? constants.PAGE_ADMIN_LOGGED_IN_HOME : constants.PAGE_USER_LOGGED_IN_HOME}
                        >
                            Home
                        </Link>
                    </li>
                    {authenticationService.currentUserValue.role === UserRoles.ADMIN && <>
                        <li className="nav-item"><Link className="nav-link" to={constants.PAGE_ADMIN_USERS}>Usuarios</Link></li>
                    </>}

                    {authenticationService.currentUserValue.role === UserRoles.USER && <>
                        <li className="nav-item"><Link className="nav-link" to={constants.PAGE_USER_PARTES}>Partes</Link></li>
                        <li className="nav-item"><Link className="nav-link" to={constants.PAGE_USER_SOLICITAR_PARTES}>Solicitar Trabajo</Link></li>
                    </>}

                    <li className="nav-item"><span className="nav-link" onClick={() => authenticationService.logout()}>Salir <small>({authenticationService.currentUserValue.username})</small></span></li>
                </ul>
            </div>
        </div>
    </nav>
}

export default MenuLoggedIn;
