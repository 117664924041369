import React from "react";
import s from './ivace.module.css'


export default function Ivace() {

    return (
        <div className={s.ivace}>
            <p className="text-black">Cofinanciado por la Unión Europea</p>
            <img alt="ivace"  src={process.env.PUBLIC_URL + "/ivace.jpg"}/>
        </div>
    )
}
