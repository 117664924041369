import React from 'react';

const Logo = props => {

    const width = props.width || 220;
    const height = props.height || 220;

    return <img src={process.env.PUBLIC_URL + '/logo.png'} {...props} width={width} height={height} alt="Partes de trabajo en la nube"/>
}

export default Logo;
