import React from 'react';
import UsersList from "../components/users/users.list";
import { Link } from 'react-router-dom';
import constants from "../constants";

const UsersPage = () => <div>
    <h2>Usuarios</h2>

    <UsersList />

    <Link to={constants.PAGE_ADMIN_USER_CREATE} className="btn btn-primary">Crear usuario</Link>
</div>

export default UsersPage;
