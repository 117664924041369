import React from 'react';
import constants from "../constants";
import {Link} from "react-router-dom";

const HomeLoggedIn = props => <div>
    <h1>¿Qué puedo hacer aquí?</h1>
    <p>Le ayudamos a que mejore la comunicación con su cliente</p>
    <p>Este área de administración está creada para que su empresa pueda dar acceso a los cliente a visualizar los partes de trabajo y su estado. Su cliente, además, podrá solicitar un trabajo. Este trabajo se lo comunicamos al email con el que usted se registro en la app de partes de trabajo.</p>
    <p>Si necesitas ayuda o tienes cualquier consulta, puedes contactar no nosotros en el correo info@partedetrabajo.com o en nuestra web https://www.partedetrabajo.com.</p>
    <Link to={constants.PAGE_ADMIN_USER_CREATE} className="btn btn-primary">Crear un usuario ahora</Link>
</div>;

export default HomeLoggedIn;
