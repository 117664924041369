/**
 * https://dev.to/shane/working-with-enums-in-angular-html-templates-2io9
 * @param enumeration
 */

const enumToArray = enumeration => Object.keys(enumeration).filter(key => isNaN(+key));

export {
    enumToArray
}

