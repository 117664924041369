import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import constants from "../constants";
import { ParteModel } from "backend/dist/parte/parte.model";
import LineasActuacionList from "../components/lineasActuacion/LineasActuacionList";
import LineasProductoList from "../components/lineasProducto/LineasProductoList";
import { parseDate } from "../util/util";
import FotosParte from "../components/galeriaFotos/FotosParte";
import { SignatureForm } from "../components/firma/firmaForm";
import { authenticationService } from "../services/authentication.service";

const ParteDetailPage = (props) => {
  const [item, setItem] = useState<ParteModel>(null);
  const { data } = useSWR(constants.API_URL + constants.EP_PARTES);
  const { id } = useParams<any>();

  const permitir_firma = authenticationService.currentUserValue.permitir_firma === 1

  useEffect(() => {
    if (!data || !data.docs) return;

    const res = data.docs.find((i) => i.id === id);
    setItem(res);
  }, [data, id]);

  if (!item) return null;

  return (
    <div>
      <h3>Número parte {`${item.numero}-${item.ejercicio}`}</h3>

      <div className="row">
        <div className="col-md-6">
          <p>Inicio: {parseDate(item.horaIni)}</p>
        </div>

        <div className="col-md-6">
          <p>Fin: {parseDate(item.horaFin)}</p>
        </div>
      </div>

      {item.trabajoSolicitado?.length > 0 && (
        <div className="row">
          <div className="col">
            <h5>Trabajo Solicitado:</h5>
            <p>{item.trabajoSolicitado}</p>
          </div>
        </div>
      )}

      {item.notas?.length > 0 && (
        <div className="row">
          <div className="col">
            <h5>Notas:</h5>
            <p>{item.notas}</p>
          </div>
        </div>
      )}

      <LineasActuacionList items={item.lineasActuacion} />

      <LineasProductoList items={item.lineasProducto} />

      <h3>Datos firmante</h3>

      {(!item.firma || item.firma.trim().length === 0) && permitir_firma && <SignatureForm />}

      <p>
        {item.firma?.length > 0 && item.dniFirma?.length > 0 && (
          <div>
            DNI: {item.dniFirma}
            <br />
          </div>
        )}
        {item.firma?.length > 0 && item.personaFirmante?.length > 0 && (
          <div>
            Firmante: {item.personaFirmante}
            <br />
          </div>
        )}
        {item.firma?.length > 0 && (
          <img src={item.firma} alt={`Firma ${item.personaFirmante}`} />
        )}
      </p>

      {
        // todo fotos
      }
      <FotosParte />

      <p style={{ paddingTop: "50px" }}>
        <Link to="/partes" className="btn btn-primary">
          Volver
        </Link>
      </p>
    </div>
  );
};

export default ParteDetailPage;
