import React, { useState } from "react";
import { Formik, Field } from "formik";
import { authHeader } from "../../util";
import { alertService } from "../../components/alert/alert.service";
import SignatureCanvas from "react-signature-canvas";
import constants from "../../constants";
import constansts from "../../constants";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

export interface FirmaDto {
  firma: string;
  dniFirma: string;
  personaFirmante: string;
}

const FirmarParteSchema = Yup.object().shape({
  dniFirma: Yup.string().nullable(),
  personaFirmante: Yup.string().required(
    "Debe introducir el nombre de la persona firmante."
  ),
  // TODO :: Pte validar la firma
  // firma: Yup.string()
  //   .required()
  //   .matches(
  //     /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
  //     "La firma es obligatoria"
  //   ),
});

export const SignatureForm = () => {
  const initialValues: FirmaDto = {
    firma: "",
    dniFirma: "",
    personaFirmante: "",
  };
  const [loading, setLoading] = useState(false);
  const [signed, setSigned] = useState(false);

  const sigPad = React.useRef<SignatureCanvas>(null);
  const { id } = useParams<any>();

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={FirmarParteSchema}
        onSubmit={async (values: any) => {
          setLoading(true);
          const current = sigPad.current;
          const data = current.toDataURL();
          const postData = { ...values, firma: data };
          const requestOptions: RequestInit = {
            method: "PATCH",
            headers: {
              ...authHeader(),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          };

          let ep = `${constansts.API_URL}${constants.EP_PARTES_FIRMAR}`;
          ep = ep.replace("{id}", id);

          fetch(ep, requestOptions)
            .then((r) => r.json())
            .then((r) => {
              console.log("Respuesta de firma", r);
              console.log(r, typeof r);
              if ((r.statusCode && r.statusCode >= 400) || !r) {
                const error =
                  (r && r.error && r.error.message) ||
                  "Ha habido un problema intentando enviar los datos. Por favor, recargue la página y vuelva a intentarlo.";
                alertService.error(error, {});
              } else {
                alertService.success("Se ha firmado correctametne.", {});
              }
            })
            .catch((error) => console.error({ error }))
            .finally(() => setLoading(false));
        }}
      >
        {({ isValid, values, errors, touched, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <label htmlFor="dniFirma">
                <Field
                  name="dniFirma"
                  type="text"
                  placeholder="DNI"
                  value={values.dniFirma}
                />
              </label>
            </div>
            <div className="row mb-3">
              <label htmlFor="personaFirmante">
                <Field
                  name="personaFirmante"
                  type="text"
                  placeholder="Nombre"
                  value={values.personaFirmante}
                />
              </label>
            </div>
            <div className="row mb-3">
              <label htmlFor="firma">
                <Field
                  name="firma"
                  render={() => (
                    <SignatureCanvas
                      penColor="blue"
                      ref={sigPad}
                      onBegin={() => setSigned(true)}
                      canvasProps={{
                        title: "canvas",
                        width: 250,
                        height: 90,
                        style: { border: "2px solid #000" },
                      }}
                    />
                  )}
                />
              </label>
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={loading || !signed}
                  onClick={() => {
                    sigPad.current.clear();
                    setSigned(false);
                  }}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Limpiar firma
                </button>
                <input
                  style={{ marginLeft: "10px" }}
                  className="btn btn-primary"
                  type="submit"
                  value={loading ? "Espere" : "Firmar"}
                  disabled={!isValid || loading || !signed}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
