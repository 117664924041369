import {authenticationService} from "./services/authentication.service";

export function authHeader () {
    const currentUser = authenticationService.currentUserValue;
    const at = localStorage.getItem('access_token');

    if (currentUser && at) {

        return { Authorization: `Bearer ${at}` };
    } else {
        return {};
    }
}
