import React, {useContext} from "react";
import AppContext from "../context/app.context";
import Spinner from "../components/spinner/spinner";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import GuestLayout from "../layouts/GuestLayout";
import LoggedInLayout from "../layouts/LoggedInLayout";
import LoginPage from "../pages/LoginPage";
import constants from "../constants";
import InfoPage from "../pages/InfoPage";
import {PrivateRoute} from "./PrivateRoute";
import UsersPage from "../pages/UsersPage";
import {PublicOnlyRoute} from "./PublicOnlyRoute";
import HomeLoggedIn from "../pages/HomeLoggedIn";
import UsersDetailPage from "../pages/UsersDetailPage";
import UsersCreatePage from "../pages/UsersCreatePage";
import UsersCreateStep2 from "../pages/UsersCreateStep2";
import PartesPage from "../pages/PartesPage";
import {UserRoles} from "backend/dist/user/user.entity";
import HomeUserLoggedIn from "../pages/HomeUserLoggedIn";
import ParteDetailPage from "../pages/ParteDetailPage";
import ScrollToTop from "../components/scrolltotop/ScrollToTop";
import PartesSolicitarPage from "../pages/PartesSolicitarPage";
import Footer from "../components/footer/Footer";
// import PartesSolicitarPage from "../pages/PartesSolicitarPage";



interface RoutesProps {
    isLoggedIn: boolean
}


const Routes: React.FC<RoutesProps> = ({ isLoggedIn }) => {

    const context = useContext(AppContext)


    if (!context.loaded) {
        return <Spinner />
    }

    const Layout = isLoggedIn ? LoggedInLayout : GuestLayout;

    return <Router>

        <ScrollToTop />
        <Layout>
            <Switch>

                <PublicOnlyRoute path="/" exact component={LoginPage} />

                <Route path={constants.PAGE_INFO} exact component={InfoPage} />

                <PrivateRoute onlyForRole={UserRoles.ADMIN} path={constants.PAGE_ADMIN_LOGGED_IN_HOME} exact component={HomeLoggedIn} />
                <PrivateRoute onlyForRole={UserRoles.ADMIN} path="/users" exact component={UsersPage} />
                <PrivateRoute onlyForRole={UserRoles.ADMIN} path="/users/create-step-2/:id/:empresa" exact component={UsersCreateStep2} />
                <PrivateRoute onlyForRole={UserRoles.ADMIN} path="/users/create" exact component={UsersCreatePage} />
                <PrivateRoute onlyForRole={UserRoles.ADMIN} path="/users/:id" component={UsersDetailPage} />

                <PrivateRoute onlyForRole={UserRoles.USER} path={constants.PAGE_USER_LOGGED_IN_HOME} exact component={HomeUserLoggedIn} />
                <PrivateRoute onlyForRole={UserRoles.USER} path={constants.PAGE_USER_PARTES} exact component={PartesPage} />
                <PrivateRoute onlyForRole={UserRoles.USER} path="/partes/solicitar" exact component={PartesSolicitarPage} />
                <PrivateRoute onlyForRole={UserRoles.USER} path="/partes/:id" exact component={ParteDetailPage} />

            </Switch>
        </Layout>

        <Footer />
    </Router>;
}

export default Routes;
