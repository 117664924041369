import {DateTime} from "luxon";
import {ParteEstados} from "backend/dist/parte/parte.model";
import {enumToArray} from "./TypeScript";

const parseDate = date => {
    return DateTime.fromISO(date).toFormat('dd-MM-yyyy H:mm');
}

const tipoToString = tipo => {
    /**
     * TODO: Futuro bug
     * 30 Agosto 2021
     * Esto va por orden según los tiene joaquin en la base de datos.
     * 0 aviso
     * 1 en proceso
     * 2 finalizado
     *
     * Va contra el índice del array. Si alguna vez se borra algun indice o se cambia de orden, se va a descuadrar todo.
     */
    const tipos = enumToArray(ParteEstados);
    return tipos[+tipo]
}

export {
    parseDate,
    tipoToString
}
