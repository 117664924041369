import React from 'react';
import {LineasActuacionModel} from "backend/dist/lineasactuacion/lineasactuacion.model";
import LineaActuacionItem from "./LineaActuacionItem";

interface LineasActuacionListProps {
    items: LineasActuacionModel[]
}

const LineasActuacionList:React.FC<LineasActuacionListProps> = ({items}) => <div>
    <h5>Lineas Actuación</h5>
    {items.map(i => <LineaActuacionItem item={i} />)}
    {items.length === 0 && <p>No hay líneas de actuación.</p>}
</div>;

export default LineasActuacionList;
