import React from 'react';
import {LineasProductoModel} from "backend/dist/lineasproducto/lineasproducto.model";
import LineaProductoItem from "./LineaProductoItem";
import {authenticationService} from "../../services/authentication.service";

interface LineasProductoListProps {
    items: LineasProductoModel[]
}

const LineasProductoList:React.FC<LineasProductoListProps> = ({items}) => {

    const mostrarPrecios = authenticationService.currentUserValue.mostrar_precios === 1

    return <div>
        <h5>Lineas Producto</h5>

        {items.length > 0 ? (
            <table className="table table-striped">
                <thead>
                <th>Código</th>
                <th>Descripción</th>
                {mostrarPrecios && <th>Precio Unitario</th>}
                {mostrarPrecios && <th>Unidades</th>}
                {mostrarPrecios && <th>Total</th>}
                </thead>
                <tbody>
                {items.map(i => <LineaProductoItem item={i} mostrarPrecios={mostrarPrecios} />)}
                </tbody>
            </table>
        ) : (
            <p>No hay líneas de producto.</p>
        )}
    </div>;
}

export default LineasProductoList;
