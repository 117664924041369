import React, {useState} from 'react';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {ParteSolicitarDto} from "backend/dist/parte/dto/parte-solicitar.dto";
import TextAreaComponent from "../components/input/TextAreaComponent";
import {authHeader} from "../util";
import constansts from "../constants";
import constants from "../constants";
import {alertService} from "../components/alert/alert.service";
import DatePickerComponent from "../components/input/DatePickerComponent";


const PartesSolicitarSchema = Yup.object().shape({
    fecha: Yup.string()
        .nullable()
        .required('Debe introducir la fecha.'),
    descripcion: Yup.string()
        .min(10, 'La descripción es muy corta.')
        .required('Debe introducir la descripción'),
})

const initialValues:ParteSolicitarDto = {
    fecha: null,
    descripcion: '',
}

const PartesSolicitarPage = props => {

    const [loading, setLoading] = useState(false);

    return <div>
        <h2>Solicitar Trabajo</h2>

        <p>Cumplimente el siguiente formulario para solicitar que uno de nuestros técnicos se ponga en contacto con usted para ayudarle.</p>
        <Formik
            initialValues={initialValues}
            validationSchema={PartesSolicitarSchema}
            onSubmit={async values => {

                setLoading(true);

                const f = values.fecha as unknown as Date;

                const fecha = f.getDate() + '-' + (f.getMonth() + 1) + '-' + f.getFullYear()

                const requestOptions: RequestInit = {
                    method: 'POST',
                    headers: {
                        ...authHeader(),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      fecha,
                      descripcion: values.descripcion,
                    })
                };

                let ep = `${constansts.API_URL}${constants.EP_PARTES_SOLICITAR}`

                fetch(ep, requestOptions)
                    .then(r => r.json())
                    .then(r => {
                        console.log(r, typeof r)
                        if ((r.statusCode && r.statusCode >= 400) || !r) {
                            alertService.error('Ha habido un problema intentando enviar los datos. Por favor, recargue la página y vuelva a intentarlo.' , {})
                        }
                        else {
                            alertService.success('Su solicitud de trabajo ha sido enviada correctamente.', {})
                        }
                    })
                    .catch(error => console.error({error}))
                    .finally(() => setLoading(false))

            }}
        >
            {({isValid}) => (
                <Form>

                    <div className="row mb-3">
                        <label htmlFor="">Fecha</label>
                        <Field name="fecha" placeholder="dd/mm/aaaa" component={DatePickerComponent} inputClassName="form-control" />
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="">Descripción</label>
                        <Field name="descripcion" placeholder="introduzca descripción" component={TextAreaComponent} inputClassName="form-control" />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <input className="btn btn-primary" type="submit" value={loading ? "Espere" : "Solicitar Trabajo"} disabled={!isValid || loading} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>

    </div>
};

export default PartesSolicitarPage;
