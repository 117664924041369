import React from 'react';
import {Alert} from "../components/alert/Alert";
import Ivace from "../components/ivace/Ivace";

const GuestLayout:React.FC = props =>
<div className="guestLayout container">
    <Alert/>
    {props.children}
    <Ivace/>
</div>;

export default GuestLayout;
