import React from "react";
import { Link, useParams } from "react-router-dom";
import constants from "../constants";
import constansts from "../constants";
import * as Yup from "yup";
import { authHeader } from "../util";
import { ErrorMessage, Field, Form, Formik } from "formik";
import InputComponent from "../components/input/InputComponent";
import { UserUpdatePasswordDto } from "backend/src/user/dto/user-update-password.dto";
import { UserUpdatePricesDto } from "backend/src/user/dto/user-update-prices.dto";
import { UserUpdateFirmaPermissionDto } from "backend/src/user/dto/user-update-firma-permission.dto";
import useSWR from "swr";
import Error from "../components/error/error";
import { alertService } from "../components/alert/alert.service";
import { ParteEstados } from "backend/dist/parte/parte.model";
import { enumToArray } from "../util/TypeScript";
import { UserUpdateStatusesDto } from "backend/dist/user/dto/user-update-statuses.dto";
import { User } from "backend/dist/user/user.entity";

type UserDetailsPageParams = {
  id: string;
  name: string;
};
const UpdatePasswordUserSchema = Yup.object().shape({
  password: Yup.string().min(4).required("La contraseña es requerida"),
  passwordRepeat: Yup.string()
    .min(4)
    .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
});

const PermissionsSchema = Yup.object().shape({
  estados: Yup.array().min(
    1,
    "Debe seleccionar al menos un estado al que usuario va a tener acceso."
  ),
});

const UsersDetailPage = () => {
  // const { mutate } = useSWRConfig()
  const { id } = useParams<UserDetailsPageParams>();

  const { data, error, mutate } = useSWR<User>(
    `${constants.API_URL}${constants.EP_USER}/${id}`
  );

  if (!data) return <p>Cargando...</p>;

  if (error) return <Error />;

  return (
    <div>
      <h2>Modificar {data.username}</h2>

      <p>Desde aquí puede cambiar los datos de {data.username}.</p>

      <h4>Mostrar Precios</h4>

      <p>
        Si activa esta opción, el usuario podrá ver los precios de los productos
        en los partes.
      </p>

      <div className="mb-5">
        <Formik
          initialValues={{
            mostrar_precios: data.mostrar_precios === 1,
          }}
          onSubmit={(values) => {
            // same shape as initial values
            const sendValues: UserUpdatePricesDto = {
              mostrar_precios: values.mostrar_precios ? 1 : 0,
            };

            const requestOptions: RequestInit = {
              method: "PATCH",
              headers: {
                ...authHeader(),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(sendValues),
            };

            let ep = `${constansts.API_URL}${constants.EP_USER_UPDATE_PRICES}`;
            ep = ep.replace("{id}", id);

            fetch(ep, requestOptions)
              .then((r) => r.json())
              .then((r) => {
                console.log(r);
                if (r.statusCode && r.statusCode >= 400) {
                  alertService.error(
                    "Ha habido un error al modificar las preferencias.",
                    {}
                  );
                } else {
                  alertService.success(
                    "La opción de mostrar precios ha sido modificada correctamente.",
                    {}
                  );
                }
              })
              .catch((error) => console.error({ error }));
          }}
        >
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="row mb-3">
                  <label htmlFor="">Mostrar Precios:</label>
                  <Field
                    name="mostrar_precios"
                    type="checkbox"
                    fieldType="checkbox"
                    component={InputComponent}
                  />
                </div>

                <div className="row">
                  <div className="col">
                    <button type="submit" className="btn btn-primary">
                      Guardar
                    </button>{" "}
                    <Link
                      to={constants.PAGE_ADMIN_USERS}
                      className="btn btn-outline-danger"
                    >
                      Volver
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>

      <h4>Permitir firma</h4>

      <p>
        Si activa esta opción, el usuario podrá firmar los partes desde el área
        de clientes
      </p>

      <div className="mb-5">
        <Formik
          initialValues={{
            permitir_firma: data.permitir_firma === 1,
          }}
          onSubmit={(values) => {
            // same shape as initial values
            const sendValues: UserUpdateFirmaPermissionDto = {
              permitir_firma: values.permitir_firma ? 1 : 0,
            };

            const requestOptions: RequestInit = {
              method: "PATCH",
              headers: {
                ...authHeader(),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(sendValues),
            };

            let ep = `${constansts.API_URL}${constants.EP_USER_UPDATE_FIRMA_PERMISSION}`;
            ep = ep.replace("{id}", id);

            fetch(ep, requestOptions)
              .then((r) => r.json())
              .then((r) => {
                console.log(r);
                if (r.statusCode && r.statusCode >= 400) {
                  alertService.error(
                    "Ha habido un error al modificar las preferencias.",
                    {}
                  );
                } else {
                  alertService.success(
                    "La opción de permitir fima ha sido modificada correctamente.",
                    {}
                  );
                }
              })
              .catch((error) => console.error({ error }));
          }}
        >
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="row mb-3">
                  <label htmlFor="">Permitir firma:</label>
                  <Field
                    name="permitir_firma"
                    type="checkbox"
                    fieldType="checkbox"
                    component={InputComponent}
                  />
                </div>

                <div className="row">
                  <div className="col">
                    <button type="submit" className="btn btn-primary">
                      Guardar
                    </button>{" "}
                    <Link
                      to={constants.PAGE_ADMIN_USERS}
                      className="btn btn-outline-danger"
                    >
                      Volver
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>

      <h4>Cambiar Permisos</h4>
      <p>
        Seleccione los estados que el usuario {data.username} va a tener acceso:
      </p>

      <div className="mb-5">
        <Formik
          initialValues={{
            estados: data.estados.length > 0 ? data.estados.split(",") : [],
          }}
          validationSchema={PermissionsSchema}
          validateOnMount={true}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            // same shape as initial values
            const sendValues: UserUpdateStatusesDto = {
              estados: values.estados,
            };

            const requestOptions: RequestInit = {
              method: "PATCH",
              headers: {
                ...authHeader(),
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(sendValues),
            };

            let ep = `${constansts.API_URL}${constants.EP_USER_UPDATE_ESTADOS}`;
            ep = ep.replace("{id}", id);

            fetch(ep, requestOptions)
              .then((r) => r.json())
              .then((r) => {
                console.log(r);
                if (r.statusCode && r.statusCode >= 400) {
                  alertService.error(
                    "Ha habido un error al modificar los permisos.",
                    {}
                  );
                } else {
                  alertService.success(
                    "Permisos modificados correctamente.",
                    {}
                  );
                }
                // @ts-ignore
                mutate({ ...data, estados: values.estados.join(",") });
                // mutate({
                //     id: data.id,
                //     estados: values.estados.join(','),
                //     username: 'asd',
                //     password: 'asdad',
                //     status: UserStatus.ACTIVE,
                //     role:
                // })
                // mutate(ep, requestOptions).then(d => {
                //     console.log('mutate then', d);
                // })
              })
              .catch((error) => console.error({ error }))
              .finally(() => setSubmitting(false));
          }}
        >
          {({ values, isValid, isSubmitting, dirty }) => (
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <div className="row mb-3">
                    {enumToArray(ParteEstados).map((value, index) => (
                      <label key={`estados${index}`}>
                        <Field
                          type="checkbox"
                          name="estados"
                          value={index.toString()}
                        />{" "}
                        {value}
                      </label>
                    ))}
                    <ErrorMessage
                      name="estados"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div className="row">
                    <div className="col">
                      <button
                        disabled={!isValid || !dirty || isSubmitting}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Modificar Permisos
                      </button>{" "}
                      <Link
                        to={constants.PAGE_ADMIN_USERS}
                        className="btn btn-outline-danger"
                      >
                        Volver
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <h4>Cambiar Contraseña</h4>
      <p>Introduzca la nueva contraseña para el usuario {data.username}</p>

      <Formik
        initialValues={{
          password: "",
          passwordRepeat: "",
        }}
        validationSchema={UpdatePasswordUserSchema}
        onSubmit={(values) => {
          // same shape as initial values
          const sendValues: UserUpdatePasswordDto = {
            password: values.password,
          };

          const requestOptions: RequestInit = {
            method: "PATCH",
            headers: {
              ...authHeader(),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(sendValues),
          };

          let ep = `${constansts.API_URL}${constants.EP_USER_UPDATE_PASSWORD}`;
          ep = ep.replace("{id}", id);

          fetch(ep, requestOptions)
            .then((r) => r.json())
            .then((r) => {
              console.log(r);
              if (r.statusCode && r.statusCode >= 400) {
                alertService.error(
                  "Ha habido un error al modificar la contraseña.",
                  {}
                );
              } else {
                alertService.success(
                  "Contraseña modificada correctamente.",
                  {}
                );
              }
            })
            .catch((error) => console.error({ error }));
        }}
      >
        {() => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="row mb-3">
                  <label htmlFor="">Contraseña:</label>
                  <Field
                    name="password"
                    fieldType="password"
                    placeholder="Contraseña"
                    component={InputComponent}
                    inputClassName="form-control"
                  />
                </div>

                <div className="row mb-3">
                  <label htmlFor="">Repite la contraseña:</label>
                  <Field
                    name="passwordRepeat"
                    fieldType="password"
                    placeholder="Repita Contraseña"
                    component={InputComponent}
                    inputClassName="form-control"
                  />
                </div>

                <div className="row">
                  <div className="col">
                    <button type="submit" className="btn btn-primary">
                      Modificar Contraseña
                    </button>{" "}
                    <Link
                      to={constants.PAGE_ADMIN_USERS}
                      className="btn btn-outline-danger"
                    >
                      Volver
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UsersDetailPage;
