import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import constants from "../../constants";

// TODO :: Traer del backend en lugar de poner la interface a pelo
interface FotosParteProps {
  id: string;
  fecha: string;
  image: string; // Imagen en base64
  notas: string;
  adjuntar: boolean;
  insertar_en_parte: boolean;
  parte_id: string;
}

const FotosParte: React.FC<any> = () => {
  const [fotos, setFotos] = useState<FotosParteProps[]>([]);
  const { id } = useParams<any>();
  let ep = `${constants.API_URL}${constants.EP_PARTES_FOTOS}`;
  ep = ep.replace("{id}", id);
  const { data } = useSWR(ep);

  useEffect(() => {
    if (!data) return;

    setFotos(data);
  }, [data, id]);

  if (!fotos) return null;

  return (
    <div>
      <h3>Galería fotográfica</h3>
      {fotos.length === 0 && (
        <div className="row">
          <div className="col">
            <p style={{ fontSize: "large" }}>- Sin fotos -</p>
          </div>
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "1rem",
          gridAutoRows: "minmax(100px, auto)",
        }}
      >
        {fotos.map((foto) => (
          <div key={foto.id} style={{ padding: "0.5rem" }}>
            <img
              src={`data:image/jpeg;base64,${foto.image}`}
              alt={foto.notas}
              style={{
                maxWidth: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div style={{ fontSize: "large", fontWeight: "bold" }}>
              {foto.notas}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FotosParte;
