import React from 'react';
import constants from "../../constants";
import UserItem from "./user.item";
import useSWR from "swr";
import Error from "../error/error";


const UsersList = props => {

    const {data, error} = useSWR(constants.API_URL + constants.EP_USER)


    if (error) {
        console.log(error);
        return <Error error={error} />
    }

    if (!data)
        return <p>cargando</p>

    return <div>

        {data.length === 0 ? (
            <p>Aún no tienes ningún usuario dado de alta.</p>
        ) : (
            <div className="mb-4">
                {data.map((item, index) => <UserItem item={item} key={index}/>)}
            </div>
        )}


    </div>;
}

export default UsersList;
