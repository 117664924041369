import React from 'react';
import { Link } from "react-router-dom";
import constants from "../constants";
import {authenticationService} from "../services/authentication.service";

const HomeUserLoggedIn = props => <div>

    <p>Bienvenido al área de clientes de partes de trabajo de la empresa {authenticationService.currentUserValue.me_empresa}</p>

    <p>Hemos creado esta utilidad para que pueda realizar un seguimiento de los diferentes trabajos que
        nuestra empresa realiza para usted. También tiene la opción de ponerse en contacto con nosotros para
        solicitar una intervención, puede hacerlo desde la opción del menú superior <Link to={constants.PAGE_USER_SOLICITAR_PARTES}>solicitar trabajo</Link>.
    </p>

</div>;

export default HomeUserLoggedIn;
