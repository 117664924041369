import React, {useEffect, useState} from 'react';
import AppContext from "./app.context";
import {authenticationService} from "../services/authentication.service";

export type AppContextType = {
    loaded: boolean,
}

/**
 * Encargado de crear el contexto del usuario y cargarlo y validarlo.
 * En un principio exponía el usuario. Durante el uso me di cuenta de que es mas cómodo pillarlo de authenticationService, ya
 * que expone el observable a través de currentUserValue.
 * Con lo cual, esto actualemente, se encarga de hacer un "bootstrap" de la aplicación. La única dependencia actualmente
 * es saber si el usuario está o no logeado, y exponer el estado de la carga con loaded.
 *
 * Expone un provider AppContextType
 *
 * @param props
 */
const AppProvider:React.FC = (props) => {

    const [loaded, setLoaded] = useState(false);
    // const [accessTokenChecked, setAccessTokenChecked] = useState(false)


    /**
     * Si el usuario existe en local storage, se comprueba el access token
     * Si existe, se comprueba el access token
     *
     * El problema de esto es que cuando no estás logeado y te logeas, el login te da el access token
     * y como el usuario cambia, el access token se comprueba, cosa que no tiene sentido
     */
    useEffect(() => {
        console.log('useEffect current user');
        console.log({currentUserValue: authenticationService.currentUserValue});
        if (authenticationService.currentUserValue) {
            console.log('user.provider useEffect.currentuser: currentUser is not null');
            // if (localStorage.getItem('access_token') && !accessTokenChecked) {
            if (localStorage.getItem('access_token')) {
                console.log('user.provider useEffect.currentuser: access_token found');
                // setAccessTokenChecked(true)
                authenticationService.checkAccessToken()
                    .then(res => res.json())
                    .then(res => {
                        console.log('user.provider useEffect.currentuser: access_token check result');
                        console.log({res})

                        if (!res || res.statusCode === 401) {
                            // console.log('error access token');
                            // console.log(res.statusCode);
                            console.log('user.provider useEffect.currentuser: access_token not validated.');
                            authenticationService.removeCurrentUser();
                            window.location.replace('/');
                        }
                        authenticationService.refreshUserData()
                        setUserLoaded(true)
                    })
                    .catch(error => {
                        console.error('user.provider useEffect.currentuser: checkAccessToken catch');
                        console.error(error)
                        // nota: Si esto da error, se borra los datos actuales del usuario.
                        // esto está comentado por que si alguien hace un refresco de varias veces seguidas
                        // las promesas no van a resolver por que el navegador recarga y la petición da error.
                        // lo he quitado y las pruebas son correctas.
                        // si se viera algo, volver a activarlo, sabiendo que si alguien refresca 5 o 6 veces
                        // de golpe, se desloguea.

                        // authenticationService.removeCurrentUser();
                        // window.location.replace('/');
                        setUserLoaded(true)
                    })
            }
        }
        else {
            console.log('user.provider useEffect.currentuser: currentUser is null');
            setUserLoaded(true);
        }

    })

    const setUserLoaded = (val): void => {
        setLoaded(val)
    }


    return <AppContext.Provider value={{
        loaded,
    }}>
        {props.children}
    </AppContext.Provider>;
}

export default AppProvider;
