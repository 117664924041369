import React from 'react';
import useSWR from "swr";
import constants from "../../constants";
import Error from "../error/error";
import ParteItem from "./parte.item";
import {ParteEstados} from "backend/dist/parte/parte.model";
import {enumToArray} from "../../util/TypeScript";

interface PartesListProps {
    filterValue: ParteEstados
}

const PartesList:React.FC<PartesListProps> = ({filterValue}) => {


    const filterValueString = enumToArray(ParteEstados).find((label, value) => filterValue && value === parseInt(filterValue.toString()))
    let ep = constants.API_URL + constants.EP_PARTES;
    if (filterValue)
        ep += '?estado=' + filterValue;

    const {data, error} = useSWR(ep)

    if (error) {
        console.log(error);
        return <Error error={error}/>
    }

    if (!data)
        return <p>cargando</p>

    return <div>

        {filterValueString && <p>Filtrando por {filterValueString}</p>}

        {data.docs.length === 0 ? (
            <p>No hay ningún parte</p>
        ) : (
            <div className="mb-4">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Parte</th>
                        <th>Fecha</th>
                        <th>Trabajo Solicitado</th>
                        <th>Notas</th>
                        <th>Estado</th>
                    </tr>
                    </thead>
                    <tbody>
                        {data.docs.map((item, index) => <ParteItem key={index} item={item} />)}
                    </tbody>
                </table>

            </div>
        )}


    </div>;
}

export default PartesList;
