import React from 'react';
import {ClientesModel} from "backend/src/clientes/clientes.model";

interface UserClienteItemProptypes {
    item: ClientesModel,
    onClienteSelect: (id: string, empresa: string) => void,
    selectable: boolean,
}

const UserClienteItem:React.FC<UserClienteItemProptypes> = props => <div
        className={`mt-2 clienteItem ${props.selectable ? 'selectable' : 'no-selectable'}` }
        onClick={() => {
            if (props.selectable)
                props.onClienteSelect(props.item.id, props.item.empresa)
            else
                alert('Este cliente ya tiene un usuario creado.');
        }}
    >
    {props.item.empresa}
    </div>;

export default UserClienteItem;
