import React, {useState} from 'react';
import * as Yup from 'yup';
import {Link, Redirect, useParams} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik"
import {authHeader} from "../util";
import constants from "../constants";
import {UserCreateDto} from "backend/src/user/dto/user-create.dto";
import InputComponent from '../components/input/InputComponent';
import {alertService} from "../components/alert/alert.service";
import { ParteEstados } from "backend/dist/parte/parte.model";
import {enumToArray} from "../util/TypeScript";
import constansts from "../constants";



const CreateUserSchema = Yup.object().shape({
    username: Yup.string().min(4).required('El usuario es requerido'),
    password: Yup.string().min(4, 'La contraseña tiene que tener al menos 4 caracteres').required('La contraseña es requerida'),
    passwordRepeat: Yup.string().min(4, 'La contraseña tiene que tener al menos 4 caracteres').oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
    estados: Yup.array().min(1, 'Debe seleccionar al menos un estado al que usuario va a tener acceso.'),
})

const UsersCreateStep2 = () => {
    const { id, empresa } = useParams<any>()
    const [userCreated, setUserCreated] = useState<boolean>(false)

    if (userCreated)
        return <Redirect to={constants.PAGE_ADMIN_USERS} />


    return <div>

        <h2>Creando usuario para el cliente {id} - {empresa}</h2>

        <p>Si lo haces, tu cliente podrá entrar via a web con su usuario y contraseña para ver sus partes de trabajo</p>
        <p>Si quieres continuar con el proceso, debes crearle un usuario y contraseña:</p>

        <Formik
            initialValues={{
                username: empresa,
                password: constansts.isDev && '1234',
                passwordRepeat: constansts.isDev && '1234',
                mostrar_precios: true,
                permitir_firma: false,
                estados: [
                    // por defecto, los marcamos todos
                    // hay que pasarlos a string por que internamente son ints y formik no entiende el valor por que los tiene como cadena
                    ParteEstados['Aviso'].toString(),
                    ParteEstados['En Proceso'].toString(),
                    ParteEstados['Finalizado'].toString()
                ]
            }}
            validationSchema={CreateUserSchema}
            onSubmit={values => {

                const sendValues: UserCreateDto = {
                    username: values.username,
                    password: values.password,
                    cliente_id: id,
                    mostrar_precios: values.mostrar_precios ? 1 : 0,
                    permitir_firma: values.permitir_firma ? 1 : 0,
                    estados: values.estados
                }
                const requestOptions: RequestInit = {
                    method: 'POST',
                    headers: {
                        ...authHeader(),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(sendValues)
                };

                fetch(constants.API_URL + constants.EP_USER, requestOptions)
                    .then(r => r.json())
                    .then(r => {
                        if (r.statusCode && r.statusCode === 409) {
                            alertService.error('Error, ya existe otro usuario con este nombre', {});
                            return
                        }
                        console.log('react success');
                        alertService.success('Usuario creado correctamente', {});
                        setUserCreated(true)
                    })
                    .catch(error => console.error(error))
            }}
        >
            {({ isValid, dirty, isSubmitting}) => (
                <Form>
                    <div className="row">
                        <div className="col-md-6">

                            <div className="row mb-3">
                                <label htmlFor="">Usuario</label>
                                <Field name="username" placeholder="Usuario" component={InputComponent} inputClassName="form-control" />
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="">Contraseña:</label>
                                <Field name="password" fieldType="password" placeholder="Contraseña" component={InputComponent} inputClassName="form-control" />
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="">Repite la contraseña:</label>
                                <Field name="passwordRepeat" fieldType="password" placeholder="Repita Contraseña" component={InputComponent} inputClassName="form-control" />
                            </div>

                            <div className="row mb-3">
                                <label htmlFor="">Mostrar Precios</label>
                                <Field name="mostrar_precios" type="checkbox" fieldType="checkbox" component={InputComponent} />
                            </div>

                            <div className="row mb-3">
                                <label htmlFor="">Permitir firma</label>
                                <Field name="permitir_firma" type="checkbox" fieldType="checkbox" component={InputComponent} />
                            </div>

                            <div className="row mb-3">
                                <h2>Estados de los partes</h2>
                                <p>Seleccione los estados de los partes a los que el usuario va a tener acceso.</p>
                                {enumToArray(ParteEstados).map((value, index) => <label key={`estados${index}`}><Field type="checkbox" name="estados" value={index.toString()} /> {value}</label>)}
                                <ErrorMessage name="estados" component="div" className="error-message" />
                            </div>

                            <div className="row">
                                <div className="col">

                                    <button type="submit" className="btn btn-primary" disabled={!isValid  || isSubmitting}>Crear</button>{' '}

                                    <Link to={constants.PAGE_ADMIN_USER_CREATE} className="btn btn-outline-danger">Volver</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                )}
        </Formik>
    </div>
}

export default UsersCreateStep2;
