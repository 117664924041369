import React from 'react';
import {LineasProductoModel} from "backend/dist/lineasproducto/lineasproducto.model";

interface LineaProductoItemProps {
    item: LineasProductoModel,
    mostrarPrecios: boolean,
}

const totalLineaProducto = (item: LineasProductoModel) => (Math.round( (item.unidades * item.precioUnitario) * 100 ) / 100).toFixed(2);

const LineaProductoItem:React.FC<LineaProductoItemProps> = ({item, mostrarPrecios}) => <tr>
    <td>{item.codigo}</td>
    <td>{item.descripcion}</td>
    {mostrarPrecios && <td>{item.precioUnitario}</td>}
    {mostrarPrecios && <td>{item.unidades}</td>}
    {mostrarPrecios && <td>{totalLineaProducto(item)}</td>}
</tr>;

export default LineaProductoItem;
