import {BehaviorSubject} from "rxjs";
import constants from "../constants";
import {UserValidatedDto} from "backend/src/auth/dto/user-validated.dto";
import {authHeader} from '../util';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const login = async (username: string, password: string): Promise<any> => {
    const ep = constants.API_URL + 'auth/login';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        // credentials: 'same-origin',
        body: JSON.stringify({username, password})
    }

    return fetch(ep, options)
        .then(r => {
            console.log('response');
            console.log(r);


            return r.json()
        }).then(async d => {
            if (!d.access_token)
                throw new Error('Wrong credentials');

            localStorage.setItem('access_token', d.access_token)
            localStorage.setItem('tuid', d.tuid)
            await refreshUserData()
        })
}

async function refreshUserData() {

    console.log('authentication.service refreshUserData');
    const ep = constants.API_URL + constants.EP_USER_DATA;

    const options: RequestInit = {
        // method: 'POST',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
    }

    return fetch(ep, options)
        .then(r => r.json())
        .then(d => {
            // console.log('authentication.service refreshUserData then');
            // console.log({d});
            localStorage.setItem('currentUser', JSON.stringify(d));
            currentUserSubject.next(d);
            if (d.statusCode && d.statusCode === 401) {
                console.log('authentication.service refreshUserData catch');
                removeCurrentUser();
                console.error(d);
            }

        }).catch(e => {
            console.log('authentication.service fetch catch');
            console.log(e);
        })

}

function removeCurrentUser() {
    console.log('authentication.service.removeCurrentUser')
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    localStorage.removeItem('tuid');
}

function logout(): void {
    removeCurrentUser();
    currentUserSubject.next(null);
}

function checkAccessToken(): Promise<any> {

    const options: RequestInit = {
        // method: 'POST',
        headers: {
            ...authHeader()
        },
    }

    return fetch(constants.API_URL + constants.EP_AUTH_CHECK_ACCESS_TOKEN, options)

}

export const authenticationService = {
    login,
    logout,
    removeCurrentUser,
    refreshUserData,
    checkAccessToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue(): UserValidatedDto|null {
        return currentUserSubject.value
    }
}
