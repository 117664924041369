import React, {useState} from 'react';
import {ParteEstados} from "backend/dist/parte/parte.model";
import ParteEstadosDropdown from "./parte.estados.dropdown";


interface ParteFilterProps {
    onFilter: (v: ParteEstados) => void
}
const ParteFilter:React.FC<ParteFilterProps> = ({onFilter}) => {

    const [currentValue, setCurrentValue] = useState<ParteEstados>(null)

    return <div className="mb-4">
        <ParteEstadosDropdown  onChange={value => setCurrentValue(value)}/>
        <button className="btn btn-secondary" onClick={() => onFilter(currentValue)}>Filtrar</button>
    </div>;
}

export default ParteFilter;
