import React, {useState} from 'react';
import constants from "../constants";
import {authHeader} from "../util";
import UserClienteItem from "../components/users/user.cliente.item";
import {ClientesModel} from "backend/src/clientes/clientes.model";
import {Redirect} from "react-router-dom";
import useSWR from "swr";
const queryString = require('query-string');




const UsersCreatePage = () => {

    const [nombre, setNombre] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<ClientesModel[]>([])
    const [redirect, setRedirect] = useState<{ id: string, empresa: string }>()
    const [formSubmitted, setFormSubmitted] = useState(false);

    const {data:userData} = useSWR(constants.API_URL + constants.EP_USER)

    const search = (e) => {
        e.preventDefault();
        setLoading(true);

        const ep = constants.API_URL + constants.EP_CLIENTES +'/?'+ queryString.stringify({nombre})
        const headers:RequestInit = {
            headers: { ...authHeader()},
        }

        return fetch(ep, headers)
            .then(res => res.json())
            .then(d => {
                console.log(`Recibo ${d.length} resultados`)
                setData(d)
            })
            .catch(error => console.error(error))
            .finally(() => {
                setLoading(false)
                setFormSubmitted(true)
            })
    }

    const onClienteSelect = (id: string, empresa: string) => {
        setRedirect({id, empresa})
    }

    if (redirect) {
        return <Redirect to={constants.PAGE_ADMIN_USER_CREATE_STEP_2 + redirect.id + '/' + redirect.empresa} />
    }


    const clientsIds = userData ? userData.map(c => c.cliente_id) : [];

    return <div>

        <h2>Crear un nuevo usuario</h2>

        <p>Introduce el usuario de partes de trabajo en la nube.</p>

        <div className="row">
            <div className="col-md-6">
                <form action={constants.PAGE_ADMIN_USER_CREATE} onSubmit={search}>
                    <div className="input-group">
                        <input
                            type="text"
                            name="nombre"
                            value={nombre}
                            className="form-control"
                            autoComplete="off"
                            onChange={e => setNombre(e.target.value)}
                        />

                        <button
                            disabled={nombre.length === 0 || loading}
                            className="btn btn-primary"
                        >
                            Buscar
                        </button>
                    </div>
                </form>
            </div>
        </div>

        {data.length > 0 && <div className="mt-4">
            <h5>Resultado de su búsqueda</h5>
            {data.map(item => <UserClienteItem
                key={item.id}
                item={item}
                selectable={!clientsIds.includes(item.id)}
                onClienteSelect={onClienteSelect}
            />)}
        </div>}

        {formSubmitted && data.length === 0 && <p className="mt-3">Su búsqueda no produjo resultados.</p>}
    </div>
};

export default UsersCreatePage;
