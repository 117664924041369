import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {authenticationService} from "../services/authentication.service";
import constants from "../constants";
import {UserRoles} from "backend/dist/user/user.entity";


export const PublicOnlyRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (currentUser) {

            const pathname = currentUser.role === UserRoles.ADMIN ? constants.PAGE_ADMIN_LOGGED_IN_HOME : constants.PAGE_USER_LOGGED_IN_HOME
            return <Redirect to={{ pathname , state: { from: props.location } }} />

        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
