import React, {useState} from 'react';
import {ErrorMessage} from "formik";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('es', es)

const DatePickerComponent = ({field,  form: {touched, errors, setFieldValue}, ...props}) => {

    const error = touched && errors[field.name];
    let className = props.className ?? ''

    const [date, setDate] = useState();

    if (error)
        className = 'is-invalid'


    return <div className={className}>

        <DatePicker
            selected={date}
            onChange={(d) => {

                console.log(d);
                setFieldValue(field.name, d);
                setDate(d)
            }}
            locale="es"
            dateFormat="dd-MM-yyyy"
        />

        <ErrorMessage name={field.name} component="div"/>
    </div>
}


export default DatePickerComponent;
