import React from 'react';
import {ErrorMessage} from "formik";

const InputComponent = ({field, form: {touched, errors}, ...props}) => {


    const error = touched && errors[field.name];
    let className = props.className ?? ''
    const type = props.fieldType || 'text';

    if (error)
        className = 'is-invalid'


    return <div className={className}>

        <input
            {...field}
            type={type}
            className={props.inputClassName}
        />

        <ErrorMessage name={field.name} component="div" className="error-message" />
    </div>
}

export default InputComponent;
