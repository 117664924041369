import React from 'react';
import {LineasActuacionModel} from "backend/dist/lineasactuacion/lineasactuacion.model";

interface LineaActuacionItemProps {
    item: LineasActuacionModel
}
const LineaActuacionItem:React.FC<LineaActuacionItemProps> = ({item}) => <div>
    <p>{item.descripcion}</p>
</div>;

export default LineaActuacionItem;
