import React from 'react';
import { Link } from 'react-router-dom';
import {parseDate, tipoToString} from "../../util/util";


const ParteItem = ({item}) => {
    let { trabajoSolicitado, notas } = item;


    if (trabajoSolicitado.length > 85)
        trabajoSolicitado = trabajoSolicitado.substr(0, 85) + '...';

    if (notas.length > 85)
        notas = notas.substr(0, 85) + '...';

    return <tr>
        <td><Link to={`/partes/${item.id}`}>{`${item.numero}-${item.ejercicio}`}</Link></td>
        <td><Link to={`/partes/${item.id}`}>{parseDate(item.horaIni)}</Link></td>
        <td><Link to={`/partes/${item.id}`}>{trabajoSolicitado}</Link></td>
        <td><Link to={`/partes/${item.id}`}>{notas}</Link></td>
        <td><Link to={`/partes/${item.id}`}>{tipoToString(item.estado)}</Link></td>
    </tr>;
}

export default ParteItem;
