import React, {useState} from 'react';
import {authenticationService} from "../services/authentication.service";
import { UserCredentialsDto} from "backend/dist/user/dto/user-credentials.dto";
import {Field, Form, Formik} from "formik"
import Logo from "../components/logo/logo";
import * as Yup from "yup";
import InputComponent from "../components/input/InputComponent";
import constansts from "../constants";
import {alertService} from "../components/alert/alert.service";


const initialValues:UserCredentialsDto = {
    username: constansts.isDev ? 'chux' : '',
    password: constansts.isDev ? 'prueba' : ''
}

const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .min(4, 'El usuario es muy corto')
        .required('El usuario es requerido'),
    password: Yup.string()
        .min(4, 'La contraseña es muy corta')
        .required('La contraseña es requerida'),
})

const LoginPage = () => {

    const [loading, setLoading] = useState(false);

    return <div className="d-flex flex-column align-items-centers login-form">

        <div className="mx-auto" style={{width: 220}}>
            <Logo  />
        </div>

        <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={async values => {

                setLoading(true);
                const { username, password } = values;

                try {
                    await authenticationService.login(username, password)
                } catch (error) {
                    alertService.error('Datos de acceso incorrectos', {});
                    console.error(error);
                    setLoading(false);
                }
            }}
        >
            {({isValid}) => (
                <Form>
                    <div className="row mb-3">
                        <label htmlFor="">Usuario</label>
                        <Field name="username" placeholder="Usuario" component={InputComponent} inputClassName="form-control" />
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="">Contraseña</label>
                        <Field name="password" fieldType="password" placeholder="Contraseña" component={InputComponent} inputClassName="form-control" />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            {loading ? 'espere' : <input className="btn btn-primary" type="submit" value="Entrar" disabled={!isValid} />}
                        </div>
                    </div>

                </Form>
            )}
        </Formik>
    </div>
}

export default LoginPage;
