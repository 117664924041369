import React from 'react';
import MenuLoggedIn from "../components/menu/MenuLoggedIn";
import {Alert} from "../components/alert/Alert";
import Ivace from "../components/ivace/Ivace";


const LoggedInLayout:React.FC = props => {

    return <div className="loggedInLayout container">
        <MenuLoggedIn />
        <Alert />
        <div className="main-content mt-5">
            {props.children}
            <Ivace/>
        </div>

    </div>;
}

export default LoggedInLayout;
