import React from 'react';
import { enumToArray } from "../../util/TypeScript";
import { ParteEstados } from "backend/dist/parte/parte.model";
import {authenticationService} from "../../services/authentication.service";

interface ParteEstadosDropdownProps {
    onChange: (val: ParteEstados) => void
}

const ParteEstadosDropdown:React.FC<ParteEstadosDropdownProps> = ({onChange}) => {

    const availableStatuses = authenticationService.currentUserValue.estados.split(',') || [];

    let statuses = enumToArray(ParteEstados).filter((estado, i) => availableStatuses.includes(i.toString()) );
    if (statuses.length === 0)
        statuses = enumToArray(ParteEstados);

    return <select className="form-select" onChange={e => {
        const value = e.target.value as any as ParteEstados;
        onChange(value)
    }}>
        <option value="">Seleccione Estado</option>
        {statuses.map((value, index) => <option key={index} value={index}>{value}</option>)}
    </select>;
}

export default ParteEstadosDropdown;
