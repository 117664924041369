import React, {useEffect, useState} from 'react';
import AppProvider from "./context/app.provider";
import Routes from "./routes/Routes";
import {authHeader} from "./util";
import {SWRConfig} from "swr";
import 'bootstrap/dist/css/bootstrap.css';
import './css/custom.css';
import {authenticationService} from "./services/authentication.service";

function App() {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        authenticationService.currentUser.subscribe(u => {
            console.log('App authenticationService suscription');
            setIsLoggedIn(u !== null);
        })
    }, [])

    return (
        <AppProvider>
            <SWRConfig
                value={{

                    fetcher: async (url) => {
                        const headers: RequestInit = {
                            headers: {...authHeader()}
                        }

                        console.log('useSwr custom fetch request');
                        console.log({headers, url})
                        const res = await fetch(url, headers);

                        if (!res.ok) {
                            throw new Error('An error occurred while fetching the data.')
                        }

                        return res.json();

                    }
                }}
            >
                <Routes isLoggedIn={isLoggedIn}/>
            </SWRConfig>
        </AppProvider>
    );
}

export default App;
