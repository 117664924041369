const constansts = {
  isDev: process.env.NODE_ENV === "development",
  API_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/"
      : "https://apiclientes.partedetrabajo.com/",
  EP_USER: "user",
  EP_USER_DATA: "user/data",
  EP_AUTH_CHECK_ACCESS_TOKEN: "auth/check-access-token",
  EP_AUTH_LOGIN: "auth/login",
  EP_CLIENTES: "clientes",
  EP_USER_UPDATE_PASSWORD: "user/{id}/update-password",
  EP_USER_UPDATE_ESTADOS: "user/{id}/update-statuses",
  EP_USER_UPDATE_PRICES: "user/{id}/update-prices",
  EP_USER_UPDATE_FIRMA_PERMISSION: "user/{id}/update-firma-permission",
  EP_PARTES: "parte",
  EP_PARTES_FOTOS: "parte/{id}/fotos",
  EP_PARTES_SOLICITAR: "parte/solicitar",

  PAGE_INFO: "/info",
  PAGE_ADMIN_USERS: "/users",
  PAGE_ADMIN_USERS_DETAIL: "/users/",
  PAGE_ADMIN_LOGGED_IN_HOME: "/home-admin",
  PAGE_ADMIN_USER_CREATE: "/users/create",
  PAGE_ADMIN_USER_CREATE_STEP_2: "/users/create-step-2/",

  PAGE_USER_LOGGED_IN_HOME: "/home-user",
  PAGE_USER_PARTES: "/partes",
  PAGE_USER_SOLICITAR_PARTES: "/partes/solicitar",

  EP_PARTES_FIRMAR: "parte/{id}/firmar",
};

export default constansts;
