import React, {useState} from 'react';
import PartesList from "../components/partes/partes.list";
import ParteFilter from "../components/partes/parte.filter";
import {ParteEstados} from "backend/dist/parte/parte.model";



const PartesPage = props => {

    const [filterValue, setFilterValue] = useState<ParteEstados>(null)

    return <div>
        <ParteFilter onFilter={value => setFilterValue(value)} />
        <PartesList filterValue={filterValue} />
    </div>;
}

export default PartesPage;
