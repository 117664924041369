import React from 'react';
import {User} from "backend/dist/user/user.entity";
import constants from "../../constants";
import {authHeader} from "../../util";
import {mutate} from "swr";
import {Link} from "react-router-dom";

type UserItemPropTypes = {
    item: User
}

const UserItem:React.FC<UserItemPropTypes> = ({item}) => {

    const handleDelete = (id:number) => {
        if (!window.confirm(`Confirma que que quieres eliminar el usuario ${item.username}`))
            return;
        const ep = constants.API_URL + constants.EP_USER + '/' + id.toString()

        const headers:RequestInit = {
            method: 'DELETE',
            headers: { ...authHeader()}
        }

        fetch(ep, headers)
            .then(res => res.json())
            .then(data => console.log(data))
            .catch(error => console.error(error))
            .finally(() => mutate(constants.API_URL + constants.EP_USER))
    }

    return <div className="clienteItem noHover">
        <div className="row">

            <div className="col">
                <Link to={`${constants.PAGE_ADMIN_USERS_DETAIL}${item.id}`}>
                    {item.username}
                </Link>
            </div>
            <div className="col">
                {item.mostrar_precios === 1 ? 'Mostrar Precios' : 'No Mostrar precios'}
            </div>

            <div className="col justify-content-end d-flex">
                <span onClick={ () => handleDelete(item.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                  height="16" fill="currentColor"
                                                                  className="bi bi-trash" viewBox="0 0 16 16">
  <path
      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fillRule="evenodd"
        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg></span>
            </div>

        </div>

    </div>;
}

export default UserItem;
