import React from 'react';
import s from './footer.module.css'

const Footer = props => <footer className={s.footer}>

    <div className="container">
        <div className="row">
            <div className="col">
                <p>
                    <a href="https://partedetrabajo.com/" target="_blank" rel="noreferrer" >www.partesdetrabajo.com</a>
                </p>

                <p>
                    <a href="https://partedetrabajo.com/politica-de-datos/" target="_blank" rel="noreferrer">Política de privacidad</a>
                    <a href="https://partedetrabajo.com/aviso-legal/" target="_blank" rel="noreferrer">Aviso legal</a>
                </p>

            </div>
        </div>
    </div>
</footer>;

export default Footer;
