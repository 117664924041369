import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {authenticationService} from "../services/authentication.service";
import {UserRoles} from "backend/dist/user/user.entity";

import {RouteProps} from "react-router-dom";
interface PropTypesInterface extends RouteProps{
    onlyForRole?: UserRoles
}

export const PrivateRoute:React.FC<PropTypesInterface> = ({ component: Component, onlyForRole, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;

        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }

        if (onlyForRole && currentUser.role !== onlyForRole) {
            // si el rol no cuadra, forzamos el deslogueo del usuario
            return <div><h1>Error</h1><p>No tiene permisos para ver esta página.</p></div>
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
